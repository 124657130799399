import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import Button from '@components/common/Button/Button';
import EmptyData from '@components/common/EmptyData/EmptyData';
import { Loader } from '@components/common/Loader/Loader';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSnackbar } from '@hooks/useSnackbar';
import { DatePicker } from '@components/common/DatePicker/DatePicker';
import dayjs from 'dayjs';
import { alertApi, AlertMessage, AlertMessageStatus } from '@api/alert';
import { ROUTES } from '@constants/routes';
import * as React from 'react';
import { UI_MESSAGE } from '@declarations/enum/uiMessage';

type AlertForm = {
  body: string;
  status?: AlertMessageStatus;
  period: [Date | undefined, Date | undefined];
};

const AlertForm = () => {
  const editorRef = useRef<any>(null);
  const { id } = useParams<{ id: string | undefined }>();
  const history = useHistory();
  const { addSnack } = useSnackbar();
  const [formData, setFormData] = useState<AlertForm>({
    body: '',
    period: [new Date(), new Date()],
  });
  const [isLoadAlert, setIsLoadAlert] = useState(!!id);
  const [errLoadAlert, setErrLoadAlert] = useState(false);

  const onChange = useCallback(
    (newData: { [key: string]: any }) =>
      setFormData((prevState: any) => ({
        ...prevState,
        ...newData,
      })),
    [],
  );

  const onSubmit = useCallback(
    async (status: AlertMessageStatus) => {
      try {
        const bodyContent = editorRef.current?.getContent({ format: 'text' });
        if (bodyContent && formData.period[0] && formData.period[1]) {
          const alertMessage = await mapToAlert(formData, bodyContent, status);
          if (id) {
            await alertApi.updateMessage(alertMessage, +id);
          } else {
            await alertApi.createMessage(alertMessage);
          }
          addSnack(
            id
              ? 'Системное оповещение обновлено'
              : 'Системное оповещение создано',
            'success',
          );
          history.push(`${ROUTES.managerAlertList}`);
        } else {
          addSnack('Заполните все поля', 'error');
        }
      } catch (err) {
        console.error(err);
        addSnack(
          id
            ? 'Ошибка при обновлении системного оповещения'
            : 'Ошибка при создании системного оповещения',
          'error',
        );
      }
    },
    [addSnack, formData, history, id],
  );

  const handleSave = useCallback(
    () => onSubmit(AlertMessageStatus.DRAFT),
    [onSubmit],
  );

  const handlePublish = useCallback(
    () => onSubmit(AlertMessageStatus.PUBLISHED),
    [onSubmit],
  );

  useEffect(() => {
    const loadData = async (id: number) => {
      try {
        const alertItem = await alertApi.getMessageById(id);
        if (alertItem) {
          setFormData(mapAlertToFormData(alertItem));
        }
      } catch (err) {
        setErrLoadAlert(true);
        addSnack(UI_MESSAGE.ALERT_NOT_AVAILABLE, 'error');
      } finally {
        setIsLoadAlert(false);
      }
    };

    if (id && +id) {
      loadData(+id);
      return;
    }
    if (id && id !== 'add') {
      setIsLoadAlert(false);
      setErrLoadAlert(true);
      addSnack(UI_MESSAGE.ALERT_NOT_AVAILABLE, 'error');
    }
  }, [addSnack, id]);

  if (isLoadAlert) {
    return <Loader />;
  }

  if (errLoadAlert) {
    return <EmptyData />;
  }

  return (
    <div>
      <Grid container sx={{ marginBottom: '32px' }}>
        <Grid item xs>
          <Typography variant="h2" sx={{ fontWeight: '500', fontSize: '18px' }}>
            {!id ? 'Новое оповещение' : 'Редактировать оповещение'}
          </Typography>
        </Grid>
        <Grid item />
      </Grid>
      <Grid container sx={{ maxWidth: '768px' }}>
        <Grid item xs={3}>
          Текст
        </Grid>
        <Grid item xs={9}>
          <div style={{ marginBottom: '16px' }}>
            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              apiKey="kloj8h7ptguwelopnwfvh5w22t0wr6i5jg4ijc37gvuq40l6"
              initialValue={formData.body}
              outputFormat="text"
              init={{
                language: 'ru',
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
          </div>
        </Grid>
        <Grid item xs={3} mb={2}>
          Период публикации
        </Grid>
        <Grid item xs={9} mb={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <DatePicker
              withTime
              value={formData.period[0]}
              onChange={(date) =>
                onChange({
                  ...formData,
                  period: [date || undefined, formData.period[1]],
                })
              }
            />
            &nbsp; &#8212; &nbsp;
            <DatePicker
              withTime
              value={formData.period[1]}
              onChange={(date) =>
                onChange({
                  ...formData,
                  period: [formData.period[0], date || undefined],
                })
              }
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 4, p: '24px 32px', backgroundColor: '#f2f4f7' }}
        >
          <Grid container columnSpacing={2} sx={{ justifyContent: 'flex-end' }}>
            <Grid item xs="auto">
              <Button
                variant="fourth"
                sx={{ backgroundColor: '#fff' }}
                onClick={handleSave}
              >
                Сохранить
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button variant="first" onClick={handlePublish}>
                Опубликовать
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AlertForm;

const mapToAlert = async (
  form: AlertForm,
  bodyContent: string,
  status: AlertMessageStatus,
): Promise<AlertMessage> => {
  return {
    content: bodyContent,
    status,
    startDate: form.period[0] ? dayjs(form.period[0]).toISOString() : null,
    endDate: form.period[1] ? dayjs(form.period[1]).toISOString() : null,
  };
};

const mapAlertToFormData = (message: AlertMessage): AlertForm => {
  const { content, status, startDate, endDate } = message;

  return {
    body: content,
    status,
    period: [
      startDate ? new Date(startDate) : undefined,
      endDate ? new Date(endDate) : undefined,
    ],
  };
};
