import { FC, useCallback, useEffect, useState } from 'react';
import { alertApi, AlertMessage, AlertMessageStatus } from '@api/alert';
import { useSnackbar } from '@hooks/useSnackbar';
import { generateLink } from '@lib/common';
import { ROUTES } from '@constants/routes';
import { useHistory } from 'react-router';
import { Grid, Typography } from '@mui/material';
import Button from '@components/common/Button/Button';
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  ruRU,
} from '@mui/x-data-grid';
import { setPublishDate } from '@lib/date/date';

const PER_PAGE_OPTIONS = [20, 40];

const AlertList: FC = () => {
  const [alertList, setAlertList] = useState<AlertMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const { addSnack } = useSnackbar();
  const history = useHistory();

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const alertMessageList = await alertApi.getAllMessages();
      if (alertMessageList) {
        setAlertList(alertMessageList);
      }
    } catch (err) {
      console.error(err);
      addSnack('Ошибка загрузки данных', 'error');
    } finally {
      setLoading(false);
    }
  }, [addSnack]);

  const handleRemove = useCallback(
    async (id) => {
      if (id) {
        try {
          await alertApi.removeMessage(id);
          await loadData();
          addSnack('Системное оповещение удалено', 'success');
        } catch (err) {
          console.error(err);
          addSnack('Ошибка удаления системного оповещения', 'error');
        }
      }
    },
    [addSnack, loadData],
  );

  const handleEdit = useCallback(
    (id) => {
      if (id) {
        const alertLink = generateLink(ROUTES.managerAlertItem, {
          id,
        });
        history.push(alertLink);
      } else {
        addSnack('Выберите оповещение для редактирования', 'error');
      }
    },
    [addSnack, history],
  );

  const columns: GridColDef[] = [
    { field: 'content', headerName: 'Содержимое', flex: 3, minWidth: 300 },
    {
      field: 'startDate',
      headerName: 'Дата публикации',
      flex: 2,
      renderCell: (params: GridRenderCellParams<AlertMessage>) => {
        const status = params.row.status;
        const date =
          params.row.startDate && setPublishDate(params.row.startDate);
        return status === AlertMessageStatus.PUBLISHED ? (
          date
        ) : (
          <div className="news-list__status-label">черновик</div>
        );
      },
    },
    {
      field: 'endDate',
      headerName: 'Период актуальности',
      flex: 2,
      renderCell: (params: GridRenderCellParams<AlertMessage>) => {
        const status = params.row.status;
        const endDate =
          params.row.endDate && setPublishDate(params.row.endDate);
        return status === AlertMessageStatus.PUBLISHED && endDate;
      },
    },
    {
      field: 'Управление оповещениями',
      headerName: '',
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<AlertMessage>) => {
        const id = params.row.id;
        return (
          <Grid container spacing={1}>
            <Grid item xs="auto">
              <Button variant="fourth" onClick={() => handleEdit(id)}>
                Изменить
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button variant="delete" onClick={() => handleRemove(id)}>
                Удалить
              </Button>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const [pageSize, setPageSize] = useState<number>(PER_PAGE_OPTIONS[0]);

  const handlePageSizeChange = useCallback(
    (pageSize: number) => setPageSize(pageSize),
    [],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div>
      <Grid container>
        <Grid item xs alignItems="center">
          <Typography variant="h2" sx={{ fontWeight: '500', fontSize: '18px' }}>
            Системные оповещения
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="first"
            to={ROUTES.managerAlertAdd}
            startIcon={<PlusIcon />}
          >
            Добавить
          </Button>
        </Grid>
      </Grid>
      <DataGrid
        sx={{
          border: 'none',
          '& .MuiDataGrid-cell:focus': {
            outline: 0,
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 0,
          },
        }}
        autoHeight={true}
        disableSelectionOnClick
        pageSize={pageSize}
        rowsPerPageOptions={PER_PAGE_OPTIONS}
        onPageSizeChange={handlePageSizeChange}
        checkboxSelection={false}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        loading={loading}
        rows={alertList}
        columns={columns}
      />
    </div>
  );
};

export default AlertList;
